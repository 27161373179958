.App {
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logoContainer {
  position: relative;
}

/* The image */

/* The shiny shadow gradient overlay */
.logoContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  z-index: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.title{
  font-size: 2em;
  text-decoration: none;
  font-weight: lighter;
}

.subtitle{
  font-style: italic;
}

.pageDetails{
  font-size: 0.8em;
  color: gray;
  text-decoration: none;
  padding-left: 10%;
  padding-right: 10%;
}

a{
  text-decoration: underline;
  color: gray;
}

.promoLinks{
  display: inline;
  font-size: 1.2em;
  color: gray;
  text-decoration: none;
}
.promoLinks:hover{
  color: gray;
  text-decoration: none;
}
.promoLinks:visited{
  color: gray;
  text-decoration: none;
}


.App-header {
  /*background-color: #282c34;*/
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
